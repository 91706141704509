import Main from './src/Main';
/**
 * BEGIN: Enable global dependencies
 *
 * Instructions: List all global dependencies that are used in client part here
 */
window.Promise = require('es6-promise-polyfill').Promise;
require('./styles/mainBelow.scss');
document.addEventListener('DOMContentLoaded', function () {
    Main.run();
}, true);
