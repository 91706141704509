var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { trackVisibilityInWindow } from '../trackVisibilityWindow';
var LazyRecaptcha = /** @class */ (function () {
    function LazyRecaptcha() {
    }
    LazyRecaptcha.init = function () {
        var scriptEls = __spread(document.querySelectorAll('script[data-lazy-recaptcha]'));
        var form = document.querySelector('div[data-lazy-recaptcha]');
        var next = [];
        if (scriptEls && form) {
            trackVisibilityInWindow(form, function () {
                scriptEls.forEach(function (s) {
                    if (s.dataset.src) {
                        var script = document.createElement('script');
                        script.src = s.dataset.src;
                        document.body.appendChild(script);
                        script.addEventListener('load', function () {
                            next.forEach(function (el) {
                                document.body.appendChild(el);
                            });
                        });
                    }
                    else {
                        var script = document.createElement('script');
                        script.innerHTML = s.innerHTML;
                        next.push(script);
                    }
                });
            }, {
                stickToTrue: true,
            });
        }
    };
    return LazyRecaptcha;
}());
export { LazyRecaptcha };
