var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { regEmail } from '../helpers';
var Form = /** @class */ (function () {
    function Form(el, bodyScrollBar) {
        if (!el)
            return;
        el.setAttribute('novalidate', true);
        this.form = el;
        this.bodyScrollBar = bodyScrollBar;
        this.fields = el.querySelectorAll('[name]:not([type="hidden"])');
        this.submitBtn = el.querySelector('[type=submit]');
        this.isLoading = false;
        this.handlerField = this.handlerField.bind(this);
        this.handlerField();
        this.action = this.form.getAttribute('action');
        this.form.addEventListener('submit', this.onSubmit.bind(this));
        this.formInputFilledClass = 'form__input--filled';
        this.groupValidClass = 'form__group--valid';
        this.groupInvalidClass = 'form__group--invalid';
        this.groupFocusClass = 'form__group--focus';
        this.formFocusClass = 'form--focus';
        this.formSendClass = 'form--send';
        this.setPatternEmail();
        this.isAttemptSendForm = false;
    }
    Form.init = function (el, bodyScrollBar) {
        return new Form(el, bodyScrollBar);
    };
    Form.prototype.setPatternEmail = function () {
        var emailField = this.form.querySelector('[type="email"]');
        if (emailField)
            emailField.pattern = regEmail;
    };
    Form.prototype.clearAllFields = function () {
        var _this = this;
        this.fields.forEach(function (field) {
            // eslint-disable-next-line no-param-reassign
            field.value = '';
            field.classList.remove(_this.formInputFilledClass);
            var formGroup = field.parentElement;
            formGroup.classList.remove(_this.groupValidClass, _this.groupInvalidClass);
            document.querySelectorAll('[data-form-group-entry-text]').forEach(function (item) {
                // eslint-disable-next-line no-param-reassign
                item.innerHTML = '';
            });
            document.querySelectorAll('[data-form-group-border]').forEach(function (item) {
                // eslint-disable-next-line no-param-reassign
                item.style.width = '100%';
            });
        });
    };
    Form.prototype.toggleValidationClass = function (field) {
        var formGroup = field.parentElement;
        if (field.checkValidity()) {
            formGroup.classList.add(this.groupValidClass);
        }
        else {
            formGroup.classList.add(this.groupInvalidClass);
        }
    };
    Form.prototype.handlerField = function () {
        var _this = this;
        var scrollVirtualKeyboard = function (element) {
            if (/Android/i.test(navigator.userAgent)) {
                setTimeout(function () {
                    var _a;
                    (_a = _this.bodyScrollBar) === null || _a === void 0 ? void 0 : _a.scrollIntoView(element, {
                        offsetTop: 80,
                        onlyScrollIfNeeded: true,
                    });
                }, 750);
            }
        };
        this.fields.forEach(function (field) {
            var formGroup = field.parentElement;
            var entryTextBox = formGroup.querySelector('[data-form-group-entry-text]');
            var border = formGroup.querySelector('[data-form-group-border]');
            field.addEventListener('focus', function () {
                scrollVirtualKeyboard(field);
                _this.form.classList.add(_this.formFocusClass);
                formGroup.classList.add(_this.groupFocusClass);
            });
            field.addEventListener('blur', function () {
                scrollVirtualKeyboard(field);
                if (_this.isAttemptSendForm) {
                    _this.toggleValidationClass(field);
                }
                _this.form.classList.remove(_this.formFocusClass);
                formGroup.classList.remove(_this.groupFocusClass);
                // eslint-disable-next-line no-param-reassign
                field.value = field.value.trim();
                if (field.value.length > 0) {
                    field.classList.add(_this.formInputFilledClass);
                }
                else {
                    border.style.width = '100%';
                    formGroup.classList.remove(_this.groupValidClass, _this.groupInvalidClass);
                    field.classList.remove(_this.formInputFilledClass);
                }
                _this.removeGlobalMessage();
            });
            field.addEventListener('input', function () {
                formGroup.classList.remove(_this.groupValidClass, _this.groupInvalidClass);
                if (entryTextBox) {
                    if (field.value.length > 0) {
                        entryTextBox.innerText = field.value;
                        border.style.width = entryTextBox.offsetWidth + "px";
                    }
                    else {
                        border.style.width = '100%';
                    }
                }
                _this.removeGlobalMessage();
            });
        });
    };
    Form.prototype.removeGlobalMessage = function () {
        var glodalMessage = this.form.querySelector('div.form__global-message');
        if (glodalMessage)
            glodalMessage.remove();
    };
    Form.prototype.setGlobalMessage = function (r) {
        var _this = this;
        var status = r.status;
        var node;
        if (status === 200) {
            if (this.form.dataset.showContactSuccess) {
                this.form.querySelector('[data-form-btn-submit]').classList.add('form__btn-submit--hide');
                this.form.querySelector('[data-form-success-submit]').classList.add('form__success-submit--show');
                this.form.querySelector('[data-form-success-submit]').classList.add('form__success-submit--animate');
                this.form.querySelector('[data-form-success-submit-circle]').addEventListener('animationend', function () {
                    _this.form.querySelector('[data-form-check]').classList.add('form__checkmark--toggled');
                });
                this.fields.forEach(function (field) {
                    field.parentElement.classList.remove(_this.groupValidClass, _this.groupInvalidClass);
                    document.querySelectorAll('[data-form-group-border]').forEach(function (item) {
                        // eslint-disable-next-line no-param-reassign
                        item.style.width = '100%';
                    });
                });
            }
            else {
                r.json().then(function (_a) {
                    var message = _a.message;
                    node = _this.nodeGlobalMessage;
                    node.innerHTML = message || _this.form.dataset.success;
                });
            }
        }
        else if (status === 0) {
            node = this.nodeGlobalMessage;
            node.innerHTML = this.form.dataset.success;
        }
        else if (status === 400) {
            node = this.nodeGlobalMessage;
            node.classList.add('form__global-message--error');
            r.json().then(function (data) {
                if (data.errors) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    Object.entries(data.errors).forEach(function (_a) {
                        var _b = __read(_a, 2), key = _b[0], value = _b[1];
                        node.innerHTML += value + "<br>";
                    });
                }
                else if (data.message) {
                    node.innerHTML = data.message;
                }
            });
        }
        if (node) {
            this.clearAllFields();
            this.form.appendChild(node);
        }
    };
    Object.defineProperty(Form.prototype, "nodeGlobalMessage", {
        get: function () {
            var node = document.createElement('DIV');
            node.className = 'form__global-message';
            return node;
        },
        enumerable: false,
        configurable: true
    });
    Form.prototype.setLoading = function (val) {
        this.isLoading = val;
        if (val) {
            this.form.classList.add(this.formSendClass);
        }
        else {
            this.form.classList.remove(this.formSendClass);
        }
        this.fields.forEach(function (field) {
            // eslint-disable-next-line no-param-reassign
            field.disabled = val;
        });
        this.submitBtn.disabled = val;
    };
    Form.prototype.onSubmit = function (event) {
        var _this = this;
        this.isAttemptSendForm = true;
        this.removeGlobalMessage();
        this.fields.forEach(function (field) { return _this.toggleValidationClass(field); });
        event.preventDefault();
        if (!this.form.querySelector(':invalid')) {
            var submitForm = function () {
                var formData = new FormData(_this.form);
                _this.setLoading.call(_this, true);
                var headers = new Headers();
                headers.append('Access-Control-Allow-Origin', '*');
                fetch(_this.action, {
                    method: 'POST',
                    body: formData,
                    mode: 'no-cors',
                    headers: headers,
                }).then(function (r) {
                    _this.setGlobalMessage(r);
                    if (r.status !== 200) {
                        _this.setLoading(false);
                    }
                }).catch(function (r) {
                    _this.setGlobalMessage(r);
                    _this.setLoading(false);
                });
            };
            // eslint-disable-next-line no-undef
            handleRecaptcha('contact', 'recaptchaResponse', submitForm);
        }
        return false;
    };
    return Form;
}());
export default Form;
