var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var FontFaceObserver = require('fontfaceobserver');
var fontToTrack = 'Maax';
var font = new FontFaceObserver(fontToTrack);
/**
 * This tracks font used in texts loading and forces re-calc of containers
 */
font.load().then(function () {
    window.dispatchEvent(new CustomEvent('webfontloaded', {}));
    // console.log(`${fontToTrack} font loaded`);
}).catch(function () {
    console.error(fontToTrack + " font failed to load.");
});
var SplitText = /** @class */ (function () {
    function SplitText(el, options) {
        var _this = this;
        this.lines = [];
        this.lineClassName = 'split-text-line';
        this.lineChildName = 'split-text-line-child';
        this.prevWidth = -1;
        this.prevHeight = -1;
        this.el = el;
        if (this.el.splitBox) {
            return;
        }
        this.lineClassName = (options === null || options === void 0 ? void 0 : options.lineClassName) || this.lineClassName;
        this.lineChildName = (options === null || options === void 0 ? void 0 : options.lineChildName) || this.lineChildName;
        this.originalHTML = el.innerHTML;
        if (this.el.children.length) {
            console.warn('SplitText was initialized on non-text node, please revise node with html', this.originalHTML);
        }
        // NOTE: split by space but not inside html tags
        this.words = this.originalHTML.split(/(?!<[^>]+)\s+(?![^<]*>)/).filter(function (w) { return !!w; });
        this.callback = (options === null || options === void 0 ? void 0 : options.callback) || (function () { return undefined; });
        this.update();
        this.listener = function () {
            _this.update(false);
        };
        this.fontListener = function () {
            _this.update(true);
        };
        window.addEventListener('resize', this.listener);
        window.addEventListener('webfontloaded', this.fontListener);
        window.addEventListener('custom-updatesplittext', this.listener);
        this.el.splitBox = this;
    }
    SplitText.init = function (el, options) {
        return new SplitText(el, options);
    };
    SplitText.prototype.onUpdate = function (callback) {
        this.callback = callback;
    };
    SplitText.prototype.destroy = function () {
        window.removeEventListener('resize', this.listener);
        window.removeEventListener('webfontloaded', this.fontListener);
        window.removeEventListener('custom-updatesplittext', this.listener);
        this.el.innerHTML = this.originalHTML;
        this.lines = [];
        this.el.splitBox = undefined;
    };
    SplitText.prototype.update = function (force) {
        var _a;
        if (force === void 0) { force = false; }
        var computedStyle = getComputedStyle(this.el);
        // const elementWidth = this.el.clientWidth; // width with padding
        var elementHeight = this.el.clientHeight; // width with padding
        var fWidth = parseFloat(computedStyle.width) - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight);
        var fHeight = elementHeight - parseFloat(computedStyle.paddingTop) - parseFloat(computedStyle.paddingBottom);
        if (fWidth === this.prevWidth && fHeight === this.prevHeight && !force) {
            // Update not needed
            return;
        }
        this.prevWidth = fWidth;
        this.prevHeight = fHeight;
        var fakeElement = document.createElement('div');
        fakeElement.style.width = Math.floor(fWidth) + "px";
        fakeElement.style.position = 'absolute';
        fakeElement.style.pointerEvents = 'none';
        fakeElement.style.opacity = '0';
        fakeElement.style.transform = 'translate(-100%, -100%)';
        fakeElement.style.top = '0';
        fakeElement.style.left = '0';
        fakeElement.style.fontFamily = computedStyle.fontFamily;
        fakeElement.style.textTransform = computedStyle.textTransform;
        fakeElement.style.fontSize = computedStyle.fontSize;
        fakeElement.style.fontSizeAdjust = computedStyle.fontSizeAdjust;
        fakeElement.style.fontWeight = computedStyle.fontWeight;
        fakeElement.style.fontStyle = computedStyle.fontStyle;
        fakeElement.style.letterSpacing = computedStyle.letterSpacing;
        // fakeElement.style.whiteSpace = computedStyle.whiteSpace;
        var lines = [];
        this.el.parentNode.appendChild(fakeElement);
        var start = 0;
        var length = 1;
        var candidate = this.words.slice(start, length).join(' ');
        var height = 0;
        var newHeight = 0;
        while (start <= this.words.length - 1 /* && lines.length < 300 */) {
            candidate = this.words.slice(start, start + length).join(' ');
            fakeElement.innerHTML = candidate;
            height = fakeElement.offsetHeight;
            newHeight = 0;
            if (start + length <= this.words.length - 1) {
                do {
                    length++;
                    candidate = this.words.slice(start, start + length).join(' ');
                    fakeElement.innerHTML = candidate;
                    newHeight = fakeElement.offsetHeight;
                } while (newHeight <= height && start + length <= this.words.length - 1);
            }
            if (newHeight > height) {
                length--;
            }
            candidate = this.words.slice(start, start + length).join(' ');
            var div = document.createElement('div');
            div.className = this.lineClassName;
            var child = document.createElement('div');
            child.className = this.lineChildName;
            child.innerHTML = candidate;
            div.appendChild(child);
            lines.push(div);
            start += length;
            length = 1;
        }
        this.lines = lines;
        this.el.parentNode.removeChild(fakeElement);
        this.el.innerHTML = '';
        (_a = this.el).append.apply(_a, __spread(this.lines));
        this.callback();
    };
    return SplitText;
}());
export { SplitText };
