import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { getFullScreenVideoStats } from '../videoHandlers/videoHandler';
var fullscreenVisibleClass = 'showreel-fullscreen--visible';
var overlayVisibleClass = 'showreel__overlay--visible';
export default function showreelFullscreen() {
    var showreelOverlay = document.querySelector('.showreel__overlay');
    var fullscreenBox = document.querySelector('[data-showreel-fullscreen]');
    var wrapVideo = document.querySelector('[data-showreel-fullscreen-inner]');
    var nodes = document.querySelectorAll('[data-showreel]');
    nodes.forEach(function (node) {
        var videoSrcWebm = node.dataset.showreel + "video.webm";
        var videoSrcMp4 = node.dataset.showreel + "1080p.mp4";
        node.addEventListener('click', function () {
            fullscreenBox.classList.add(fullscreenVisibleClass);
            showreelOverlay.classList.add(overlayVisibleClass);
            var videoNode = document.createElement('video');
            videoNode.setAttribute('autoplay', '');
            videoNode.setAttribute('loop', '');
            var sourceTagWebm = document.createElement('source');
            sourceTagWebm.setAttribute('type', 'video/webm');
            sourceTagWebm.setAttribute('src', videoSrcWebm);
            videoNode.append(sourceTagWebm);
            var sourceTagMp4 = document.createElement('source');
            sourceTagMp4.setAttribute('type', 'video/mp4');
            sourceTagMp4.setAttribute('src', videoSrcMp4);
            videoNode.append(sourceTagMp4);
            getFullScreenVideoStats(videoNode);
            /**
             * On IOS video gets open in fullscreen, detect when it's done and close showreel
             */
            videoNode.addEventListener('webkitendfullscreen', function () {
                fullscreenBox.classList.remove(fullscreenVisibleClass);
                wrapVideo.innerHTML = '';
                showreelOverlay.classList.remove(overlayVisibleClass);
            }, false);
            wrapVideo.append(videoNode);
            // send meta info
            var sendDataLayerWatch = function () {
                if (videoNode.currentTime >= videoNode.duration * 0.75) {
                    window.dataLayer = window.dataLayer || [];
                    // eslint-disable-next-line no-undef
                    dataLayer.push({
                        event: 'custom-event',
                        'event-category': 'Starting Video',
                        'event-action': 'Processing',
                        'event-label': '75%',
                    });
                    videoNode.removeEventListener('timeupdate', sendDataLayerWatch);
                }
            };
            videoNode.addEventListener('timeupdate', sendDataLayerWatch);
        });
    });
    showreelOverlay.addEventListener('click', function () {
        fullscreenBox.classList.remove(fullscreenVisibleClass);
        wrapVideo.innerHTML = '';
        showreelOverlay.classList.remove(overlayVisibleClass);
    });
    var bounderTimeLine = gsap.timeline();
    bounderTimeLine.to('.showreel__bounce', {
        opacity: 0,
    });
    ScrollTrigger.create({
        trigger: '[data-showreel]',
        animation: bounderTimeLine,
        // Uncomment these to see how they affect the ScrollTrigger
        // markers: true,
        start: 'top',
        end: 'bottom center',
        scrub: 1,
    });
}
