var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var Accordion = /** @class */ (function () {
    function Accordion(node, bodyScrollBar) {
        var _a;
        this.node = node;
        this.dontCollapse = node.hasAttribute('data-dont-collapse');
        this.dontScroll = node.hasAttribute('data-dont-scroll');
        this.bodyScrollBar = bodyScrollBar;
        this.accordionToggler = this.node.querySelectorAll('.accordion__header');
        this.firstOpenedItemBody = (_a = this.accordionToggler[0]) === null || _a === void 0 ? void 0 : _a.nextElementSibling;
        if (this.firstOpenedItemBody) {
            /**
             * When applying `maxHeight` synchronously, `maxHeight` is sometimes calculated
             * incorrectly for some reason and accordeon item content appears clipped at
             * the bottom. A hack with adding extra 500px to `maxHeight` prevents that issue.
             */
            this.firstOpenedItemBody.style.maxHeight = this.firstOpenedItemBody.scrollHeight + 500 + "px";
        }
        this.openedItemIndex = 0;
        this.clickHandler = this.clickHandler.bind(this);
        this.tryKeepInView = this.tryKeepInView.bind(this);
        this.clickHandler();
    }
    /**
     * Run it
     * @return {Accordion}
     */
    Accordion.init = function (node, bodyScrollBar) {
        return new Accordion(node, bodyScrollBar);
    };
    Accordion.prototype.tryKeepInView = function (targetItem) {
        var _this = this;
        var openedItem = __spread(this.node.children)[this.openedItemIndex];
        var cssTransitionDuration = 600;
        var tabletMediaWidth = 992;
        var offsetTop = window.innerWidth <= tabletMediaWidth ? 68 : 0;
        if (window.ResizeObserver) {
            var resizeObserver_1 = new ResizeObserver(function () {
                var _a;
                var targetItemTop = targetItem.getBoundingClientRect().top;
                if (targetItemTop < 0) {
                    (_a = _this.bodyScrollBar) === null || _a === void 0 ? void 0 : _a.scrollIntoView(targetItem, {
                        offsetTop: offsetTop,
                    });
                }
            });
            resizeObserver_1.observe(openedItem);
            setTimeout(function () {
                resizeObserver_1.disconnect();
            }, cssTransitionDuration);
        }
    };
    /**
     * Toggle accordion
     */
    Accordion.prototype.clickHandler = function () {
        var _this = this;
        this.accordionToggler.forEach(function (toggler) {
            toggler.addEventListener('click', function () {
                var targetItem = toggler.parentElement;
                var targetItemBody = toggler.nextElementSibling;
                var openedItemClass = 'accordion__item--open';
                var openedItemPredeccessorClass = 'accordion__item--open-predecessor';
                if (targetItem.classList.contains(openedItemClass)) {
                    targetItem.classList.remove(openedItemClass);
                    targetItemBody.style.maxHeight = null;
                    _this.openedItemIndex = null;
                    _this.node.querySelectorAll('.accordion__item').forEach(function (item) {
                        item.classList.remove(openedItemPredeccessorClass);
                    });
                }
                else {
                    if (!_this.dontCollapse) {
                        if (!_this.dontScroll && _this.openedItemIndex !== null) {
                            _this.tryKeepInView(targetItem);
                        }
                        _this.openedItemIndex = __spread(_this.node.children).indexOf(targetItem);
                        _this.node.querySelectorAll('.accordion__item').forEach(function (item) {
                            item.classList.remove(openedItemClass);
                            item.classList.remove(openedItemPredeccessorClass);
                            // eslint-disable-next-line no-param-reassign
                            item.querySelector('.accordion__body').style.maxHeight = null;
                        });
                    }
                    targetItem.classList.toggle(openedItemClass);
                    if (targetItem.previousElementSibling) {
                        targetItem.previousElementSibling.classList.toggle(openedItemPredeccessorClass);
                    }
                    targetItemBody.style.maxHeight = targetItemBody.scrollHeight + "px";
                }
            });
        });
    };
    return Accordion;
}());
export default Accordion;
