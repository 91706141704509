var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { generateStats } from './common';
var intervals = [0.01, 0.1, 0.25, 0.5, 0.75, 0.9];
window.dataLayer = window.dataLayer || [];
var getVideoInfo = function (videoNode, percentIntervals) {
    var stats;
    var isShowed = false;
    var timeRim = percentIntervals[0] * videoNode.duration;
    if (percentIntervals.length === 0
        && videoNode.currentTime.toFixed() === videoNode.duration.toFixed()) {
        stats = generateStats(videoNode, '100%');
        isShowed = true;
    }
    if (percentIntervals.length !== 0 && videoNode.currentTime >= timeRim) {
        stats = generateStats(videoNode, percentIntervals[0] * 100 + "%");
        percentIntervals.shift();
    }
    return { stats: stats, isShowed: isShowed };
};
export var getVideoStats = function (videoNode) {
    var percentIntervals = __spread(intervals);
    var sendDataLayerByTimePeriod = function () {
        var isPopUpActive = document.getElementsByClassName('showreel-fullscreen--visible').length > 0;
        if (!isPopUpActive) {
            var _a = getVideoInfo(videoNode, percentIntervals), stats = _a.stats, isShowed = _a.isShowed;
            if (stats) {
                window.dataLayer.push(stats);
            }
            if (isShowed) {
                videoNode.removeEventListener('timeupdate', sendDataLayerByTimePeriod);
            }
        }
    };
    videoNode.addEventListener('timeupdate', sendDataLayerByTimePeriod);
};
export var getFullScreenVideoStats = function (videoNode) {
    var percentIntervals = __spread(intervals);
    var sendDataLayerByTimePeriod = function () {
        var _a = getVideoInfo(videoNode, percentIntervals), stats = _a.stats, isShowed = _a.isShowed;
        if (stats) {
            window.dataLayer.push(stats);
        }
        if (isShowed) {
            videoNode.removeEventListener('timeupdate', sendDataLayerByTimePeriod);
        }
    };
    videoNode.addEventListener('timeupdate', sendDataLayerByTimePeriod);
};
