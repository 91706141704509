export var regEmail = '^([\\w\\+-]+(?:\\.[\\w\\+-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-zA-Z]{2,17}(?:\\.[a-zA-Z]{2,17})?)$';
export function validateEmail(val) {
    return regEmail.test(String(val).toLowerCase());
}
export function throttle(func, ms) {
    var isThrottled = false;
    var savedArgs;
    var savedThis;
    function wrapper() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (isThrottled) {
            savedArgs = args;
            savedThis = this;
            return;
        }
        func.apply(this, args);
        isThrottled = true;
        setTimeout(function () {
            isThrottled = false;
            if (savedArgs) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = null;
                savedThis = null;
            }
        }, ms);
    }
    return wrapper;
}
export function getOffset(node) {
    if (!node)
        return null;
    var val;
    if (!node.getClientRects().length) {
        val = { top: 0, left: 0 };
    }
    else {
        var rect = node.getBoundingClientRect();
        var win = node.ownerDocument.defaultView;
        val = {
            top: rect.top + win.pageYOffset,
            left: rect.left + win.pageXOffset,
        };
    }
    return val;
}
export function isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}
export function isIPadPro() {
    return navigator.maxTouchPoints
        && navigator.maxTouchPoints > 2
        && /MacIntel/.test(navigator.platform);
}
