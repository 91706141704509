var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var isVisibleByY = function (element, threshold, needBothEdges) {
    if (threshold === void 0) { threshold = 150; }
    if (needBothEdges === void 0) { needBothEdges = false; }
    var pos = element.getBoundingClientRect();
    var topEdgeIn = pos.y > -threshold && pos.y < window.innerHeight + threshold;
    var bottomEdgeIn = pos.y + pos.height > -threshold && pos.y + pos.height < window.innerHeight + threshold;
    if (needBothEdges) {
        return (topEdgeIn && bottomEdgeIn) && !!(pos.width && pos.height);
    }
    return (topEdgeIn || bottomEdgeIn) && !!(pos.width && pos.height);
};
export var isVisibleByX = function (element, threshold, needBothEdges) {
    if (threshold === void 0) { threshold = 150; }
    if (needBothEdges === void 0) { needBothEdges = false; }
    var pos = element.getBoundingClientRect();
    var leftEdgeIn = pos.x > -threshold && pos.x < window.innerWidth + threshold;
    var rightEdgeIn = pos.x + pos.width > -threshold && pos.x + pos.width < window.innerWidth + threshold;
    if (needBothEdges) {
        return (leftEdgeIn && rightEdgeIn) && !!(pos.width && pos.height);
    }
    return (leftEdgeIn || rightEdgeIn) && !!(pos.width && pos.height);
};
var defaultOptions = {
    stickToTrue: false,
    waitScroll: false,
    threshold: 150,
    trackHorizontal: false,
    shouldBeFullyInHorizontalView: false,
    shouldBeFullyInVerticalView: false,
};
/**
 * Track when element will show up after user scrolls or resizes window
 * @param element
 * @param callback
 * @param options.stickToTrue - switches to true only once and never changes. Use when you need to track event of appearance once only
 * @param options.waitScroll - wont switch to true unless user starts resizing or scrolling even if immediately visible
 * @param options.threshold - height tolerance, by default will consider element visible even if it's 150 px under page fold
 * @param options.trackHorizontal - track horizontal screen restrictions too
 * @param options.shouldBeFullyInView - trigger visible only if fully in view
 */
export var trackVisibilityInWindow = function (element, callback, options) {
    if (options === void 0) { options = defaultOptions; }
    var visible = false;
    var _a = __assign(__assign({}, defaultOptions), options), threshold = _a.threshold, trackHorizontal = _a.trackHorizontal, shouldBeFullyInHorizontalView = _a.shouldBeFullyInHorizontalView, shouldBeFullyInVerticalView = _a.shouldBeFullyInVerticalView, stickToTrue = _a.stickToTrue, waitScroll = _a.waitScroll;
    var check = function () {
        var isVisible = isVisibleByY(element, threshold, shouldBeFullyInVerticalView) && (!trackHorizontal || isVisibleByX(element, threshold, shouldBeFullyInHorizontalView));
        var newVisible;
        if (stickToTrue) {
            newVisible = visible || isVisible;
        }
        else {
            newVisible = isVisible;
        }
        if (newVisible !== visible) {
            visible = newVisible;
            callback(newVisible);
        }
    };
    window.addEventListener('custom-scroll', check); // 'custom-scroll' is triggered in 'gsapSmoothScrollBar' and replaces native scroll
    window.addEventListener('scroll', check);
    window.addEventListener('resize', check);
    if (trackHorizontal) {
        document.body.addEventListener('touchend', check);
        window.addEventListener('custom-swipe-end', check);
    }
    if (!waitScroll) {
        check();
    }
    return visible;
};
