var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { gsap } from 'gsap';
import { SplitText } from '../SplitText';
import { trackVisibilityInWindow } from '../trackVisibilityWindow';
export var lineAnimationItemSelector = '[data-appearance-text]';
export var lineAnimationWrapperSelector = '[data-appearance-track-lines]';
export var lineAnimationThresholdValue = 'appearanceTrackLinesThreshold';
/**
 * @param {Array<HTMLElement>} texts
 * @param {boolean} selfDestroy will self destroy line separation after animation is done
 */
export var animateTexts = function (texts, selfDestroy) {
    if (selfDestroy === void 0) { selfDestroy = true; }
    hideTexts(texts);
    var lines = [].concat(texts.map(function (t) { return t.splitBox.lines.map(function (div) { return div.querySelector('div'); }); }));
    texts.forEach(function (t) {
        t.splitBox.onUpdate(function () { });
    });
    // const totalEffect = 1;
    // const singleLength = totalEffect / lines.length;
    var singleLength = 0.3;
    gsap.to(lines, {
        duration: singleLength,
        yPercent: 0,
        ease: 'power1.inOut',
        stagger: {
            // amount: totalEffect,
            each: 0.1,
        },
        onComplete: function () {
            if (selfDestroy) {
                window.addEventListener('resize', function () {
                    texts.map(function (t) {
                        /**
                         * @type {SplitText}
                         */
                        var box = t.splitBox;
                        box === null || box === void 0 ? void 0 : box.destroy();
                    });
                });
            }
        },
    });
};
/**
 * @param {Array<HTMLElement>} lines
 */
export var hideLines = function (lines) {
    gsap.set(lines, {
        yPercent: 100,
    });
};
/**
 * @param {Array<HTMLElement>} texts
 */
export var hideTexts = function (texts) {
    var lines = [].concat(texts.map(function (t) { return t.splitBox.lines.map(function (div) { return div.querySelector('div'); }); }));
    texts.forEach(function (t) {
        t.splitBox.onUpdate(function () {
            hideLines(t.splitBox.lines.map(function (div) { return div.querySelector('div'); }));
        });
    });
    hideLines(lines);
};
/**
 * @param {HTMLElement} el
 */
export var initText = function (el) {
    var texts = __spread(el.querySelectorAll(lineAnimationItemSelector));
    texts.forEach(function (e) { return SplitText.init(e); });
    hideTexts(texts);
    return texts;
};
/**
 * @param {HTMLElement} el
 */
export var initAndAnimate = function (el) {
    var texts = __spread(el.querySelectorAll(lineAnimationItemSelector));
    texts.forEach(function (e) { return SplitText.init(e); });
    animateTexts(texts);
    return texts;
};
/**
 * @param {Array<HTMLElement>} els
 */
export var trackTextsToAnimate = function (els) {
    els.forEach(function (text) {
        var texts = initText(text);
        var threshold = +(text.dataset[lineAnimationThresholdValue] || '0');
        trackVisibilityInWindow(text, function () {
            animateTexts(texts);
        }, {
            stickToTrue: true,
            waitScroll: true,
            shouldBeFullyInVerticalView: true,
            threshold: threshold,
        });
    });
};
