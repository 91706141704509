var CursorController = /** @class */ (function () {
    function CursorController(gsap) {
        this.pos = { x: -100, y: -100 };
        this.delta = { x: 0, y: 0 };
        this.magnets = [];
        this.strength = 2;
        this.activeCursorZones = [];
        this.speed = 0.55;
        this.visible = true;
        this.gsap = gsap;
        this.wrapperEl = document.querySelector('.cursor');
        this.circleEl = document.querySelector('.cursor .cursor__circle');
        this.textEl = document.querySelector('.cursor .cursor__text');
        this.revertEl = document.querySelector('.cursor .cursor__revert');
        this.start();
    }
    CursorController.init = function (gsap) {
        return new CursorController(gsap);
    };
    CursorController.prototype.start = function () {
        var _this = this;
        var cursorTargets = this.gsap.utils.toArray('[data-cursor]');
        var pathname = window.location.pathname;
        if (pathname === '/case') {
            window.addEventListener('custom-scroll', function (e) {
                var _a, _b;
                var customEvent = e;
                var cursor = document.getElementsByClassName('cursor')[0];
                var offset = customEvent.detail.offset.y;
                var cursorYPosition = Number(cursor.style.top.replace(/\D/gi, '')) + offset;
                if (_this.activeCursorZones.length === 0) {
                    _this.activeCursorZones = cursorTargets.reduce(function (acc, curr) {
                        var _a;
                        var rect = curr.getBoundingClientRect();
                        var rectShape = {
                            start: rect.top,
                            end: rect.bottom,
                            text: curr.dataset.cursor,
                            appearance: curr.dataset.cursorAppearance,
                            strength: (_a = curr.dataset.cursorReactionStrength) !== null && _a !== void 0 ? _a : '2',
                        };
                        acc.push(rectShape);
                        return acc;
                    }, []);
                }
                var block = _this.activeCursorZones.find(function (b) { return b.start < cursorYPosition && b.end > cursorYPosition; });
                if (block
                    && cursorYPosition !== 100
                    && cursorYPosition < (block === null || block === void 0 ? void 0 : block.end)) {
                    _this.updateStyle((_a = block.appearance) !== null && _a !== void 0 ? _a : '', (_b = block.text) !== null && _b !== void 0 ? _b : '', +block.strength);
                    _this.visible = true;
                    _this.gsap.to(_this.wrapperEl, {
                        duration: 0.5,
                        overwrite: true,
                        scale: 1,
                        opacity: 1,
                    });
                }
                else {
                    _this.visible = false;
                    _this.gsap.to(_this.wrapperEl, {
                        overwrite: true,
                        duration: 0.5,
                        scale: 0,
                        opacity: 0,
                    });
                }
            });
        }
        cursorTargets.forEach(function (item) {
            var _a;
            var text = item.dataset.cursor;
            var appearance = item.dataset.cursorAppearance;
            var strength = (_a = item.dataset.cursorReactionStrength) !== null && _a !== void 0 ? _a : '2';
            item.addEventListener('mouseenter', function () {
                _this.updateStyle(appearance !== null && appearance !== void 0 ? appearance : '', text !== null && text !== void 0 ? text : '', +strength);
                _this.visible = true;
            });
            item.addEventListener('mouseover', function (e) {
                if (e.target && !e.target.hasAttribute('data-our-team-member-link')) {
                    _this.gsap.to(_this.wrapperEl, {
                        duration: 0.5,
                        overwrite: true,
                        scale: 1,
                        opacity: 1,
                    });
                }
            });
            item.addEventListener('mouseleave', function () {
                _this.visible = false;
                _this.gsap.to(_this.wrapperEl, {
                    delay: 0.5,
                    overwrite: true,
                    duration: 0.5,
                    scale: 0,
                    opacity: 0,
                });
            });
        });
        this.gsap.utils.toArray('[data-cursor-magnet]').forEach(function (item) {
            var size = item.getBoundingClientRect();
            var radius = (size.width + size.height) / 2;
            var distance = +(item.dataset.cursorReactionDistance || 2);
            // console.log(distance, item.textContent);
            var strength = +(item.dataset.cursorReactionStrength || 1);
            var attraction = +(item.dataset.cursorReactionAttraction || 1);
            _this.magnets.push({
                size: radius,
                distance: distance,
                strength: strength,
                attraction: attraction,
                x: size.x + size.width / 2,
                y: size.y + size.height / 2,
                el: item,
                revertEl: item.querySelector('[data-cursor-magnet-reverse]'),
                hideEl: item.querySelector('[data-cursor-magnet-hide]'),
                transformEl: item.querySelector('[data-cursor-magnet-transform]') || item,
            });
            // console.log(this.magnets, item.dataset['data-cursor-reaction-distance'], item.dataset['data-cursor-reaction-strength'], item.dataset);
        });
        document.body.addEventListener('mousemove', function (e) {
            _this.gsap.to(_this.pos, {
                x: _this.magnet ? _this.magnet.x - 0.15 * (_this.magnet.x - e.clientX) : e.clientX,
                y: _this.magnet ? _this.magnet.y - 0.15 * (_this.magnet.y - e.clientY) : e.clientY,
                overwrite: !0,
                ease: 'expo.out',
                duration: _this.speed,
                onUpdate: function () {
                    _this.delta = {
                        x: e.clientX - _this.pos.x,
                        y: e.clientY - _this.pos.y,
                    };
                },
            });
        });
        this.updatePosition();
        this.tick();
    };
    CursorController.prototype.tick = function () {
        var _this = this;
        requestAnimationFrame(function () { return _this.tick(); });
        this.updatePosition();
    };
    CursorController.prototype.updateStyle = function (appearance, text, strength) {
        if (strength === void 0) { strength = 2; }
        this.textEl.textContent = text;
        this.wrapperEl.className = 'cursor';
        this.strength = strength;
        switch (appearance) {
            case 'white':
                this.wrapperEl.classList.add('cursor--white');
                break;
            case 'menu':
                this.wrapperEl.classList.add('cursor--menu');
                break;
            case 'mix':
                this.wrapperEl.classList.add('cursor--mix');
                break;
            case 'purple':
                this.wrapperEl.classList.add('cursor--purple');
                break;
            case 'purple-mix':
                this.wrapperEl.classList.add('cursor--purple-mix');
                break;
            default:
                this.wrapperEl.className = 'cursor';
        }
    };
    CursorController.prototype.updatePosition = function () {
        var _this = this;
        var rotate = this.strength ? CursorController.getAngle(this.delta.x, this.delta.y) : 0;
        var scale = this.strength ? CursorController.getScale(this.delta.x, this.delta.y) * this.strength : 0;
        this.gsap.set(this.wrapperEl, {
            top: this.pos.y,
            left: this.pos.x,
            rotation: rotate + "deg",
            scaleX: 1 + scale,
            scaleY: 1 - scale,
        });
        /* this.gsap.set(this.circleEl, {
          rotation: `${rotate}deg`,
          scaleX: 1 + scale,
          scaleY: 1 - scale,
        }); */
        this.gsap.set(this.revertEl, {
            rotation: -rotate + "deg",
        });
        this.magnets.forEach(function (mag) {
            var dimWrap = mag.el.getBoundingClientRect();
            mag.x = dimWrap.x + dimWrap.width / 2;
            mag.y = dimWrap.y + dimWrap.height / 2;
            mag.size = (dimWrap.width + dimWrap.height) / 4; // Needs radius here
            var dist = Math.sqrt((_this.pos.x - mag.x) * (_this.pos.x - mag.x) + (_this.pos.y - mag.y) * (_this.pos.y - mag.y));
            var extDistance = Math.max(mag.size, mag.distance * mag.size);
            // console.log(dist, extDistance, mag.size, this.pos.x - mag.x, this.pos.y - mag.y, mag.revertEl.textContent);
            if (dist < extDistance) {
                var strength = Math.min(1, (extDistance - dist) / mag.size) * mag.strength;
                var attraction = Math.min(1, (extDistance - dist) / mag.size) * mag.attraction;
                _this.gsap.set(mag.transformEl, {
                    left: attraction * (_this.pos.x - mag.x),
                    top: attraction * (_this.pos.y - mag.y),
                    rotation: strength * rotate + "deg",
                    scaleX: 1 + 2 * strength * scale,
                    scaleY: 1 - 2 * strength * scale,
                });
                if (mag.revertEl) {
                    _this.gsap.set(mag.revertEl, {
                        rotation: (-1) * strength * rotate + "deg",
                    });
                }
            }
            else {
                _this.gsap.set(mag.transformEl, {
                    left: 0,
                    top: 0,
                    rotation: '0deg',
                    scaleX: 1,
                    scaleY: 1,
                });
                if (mag.revertEl) {
                    _this.gsap.set(mag.revertEl, {
                        rotation: '0deg',
                        scaleX: 1,
                        scaleY: 1,
                    });
                }
            }
        });
    };
    CursorController.getAngle = function (x, y) { return (180 * Math.atan2(y, x)) / Math.PI; };
    CursorController.getScale = function (x, y) {
        // eslint-disable-next-line no-restricted-properties
        var n = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        return Math.min(n / 1e3, 0.15);
    };
    return CursorController;
}());
export { CursorController };
