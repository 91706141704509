import { isIPadPro, isMobile } from '../helpers';
import { trackVisibilityInWindow } from '../trackVisibilityWindow';
export default function videoPreview(el) {
    var video = el.querySelector('video');
    var eventEl = el.querySelector('[data-video-preview-event]');
    var trackMouse = !isMobile() && !isIPadPro();
    var eventOver = trackMouse ? 'mouseover' : 'touchstart';
    var eventOut = trackMouse ? 'mouseout' : 'touchend';
    if (trackMouse) {
        eventEl.addEventListener(eventOver, function () { return video.play().catch(errHandler); });
        eventEl.addEventListener(eventOut, function () { return video.pause(); });
    }
    else {
        trackVisibilityInWindow(video, function (visible) {
            if (visible) {
                video.play().catch(errHandler);
            }
            else {
                video.pause();
            }
        }, {
            threshold: 0,
            trackHorizontal: true,
            shouldBeFullyInHorizontalView: true,
            shouldBeFullyInVerticalView: false,
        });
    }
    function errHandler(error) {
        switch (error.name) {
            case 'PasswordError':
                console.log('The video is password-protected');
                break;
            case 'PrivacyError':
                console.log('The video is private');
                break;
            default:
                console.log('Some other error occurred');
                break;
        }
    }
}
