var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var TRANSITION_LENGTH = 1200;
export var defaultOptions = {
    onDone: function () { return undefined; },
    selectors: {
        main: '.preloader',
        percent: '.preloader__percentage',
        bar: '.preloader__bar>div',
    },
};
var Preloader = /** @class */ (function () {
    function Preloader(props) {
        this.targetPercent = 100;
        this.currentPercent = 0;
        this.onDoneHandlers = [];
        this.timer = null;
        this.done = false;
        this.options = __assign(__assign({}, defaultOptions), props);
        this.els = {
            main: document.querySelector(this.options.selectors.main),
            percent: document.querySelector(this.options.selectors.percent),
            bar: document.querySelector(this.options.selectors.bar),
        };
        this.init();
        this.onDoneHandlers = [this.options.onDone];
    }
    Preloader.prototype.addOnDoneHandler = function (fn) {
        if (this.done) {
            fn();
        }
        else {
            this.onDoneHandlers.push(fn);
        }
    };
    Preloader.prototype.renderPercent = function (p) {
        var _a = this.els, percent = _a.percent, bar = _a.bar;
        if (percent) {
            percent.innerText = p + "%";
        }
        if (bar) {
            bar.style.width = p + "%";
        }
    };
    Preloader.prototype.onFinish = function () {
        if (this.timer !== null) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.done = true;
        var main = this.els.main;
        this.onDoneHandlers.forEach(function (fn) { return fn(); });
        setTimeout(function () {
            if (main) {
                main.classList.add('preloader_inactive');
            }
            document.body.classList.add('preloader--done');
        }, 100);
        setTimeout(function () {
            if (main) {
                main.classList.add('preloader_hidden');
                if (document.querySelector('[data-main-scrollbar]')) {
                    document.querySelector('[data-main-scrollbar]').focus();
                }
            }
        }, TRANSITION_LENGTH);
    };
    Preloader.prototype.onTick = function () {
        var _this = this;
        if (this.currentPercent < this.targetPercent) {
            this.currentPercent += 1;
            this.renderPercent(this.currentPercent);
            if (this.currentPercent >= 100) {
                this.onFinish();
            }
            else {
                this.timer = setTimeout(function () { return _this.onTick(); }, 1);
            }
        }
    };
    Preloader.prototype.init = function () {
        var _a;
        (_a = document.querySelector('[data-preloader]')) === null || _a === void 0 ? void 0 : _a.classList.add('preloader--active');
        this.onTick();
    };
    return Preloader;
}());
export { Preloader };
