export default function ReadMore(element) {
    var originalText = element.innerHTML;
    var truncText = '';
    var textLimit = Number(element.dataset.textLimit);
    var elementDataType = element.dataset.readMore;
    var btnTextMore = element.dataset.readMoreBtnMore || 'read more';
    var btnTextLess = element.dataset.readMoreBtnLess || 'read less';
    var expandedHeight = element.offsetHeight + "px";
    // eslint-disable-next-line no-param-reassign
    element.style.transition = 'height .6s ease-out';
    // eslint-disable-next-line no-param-reassign
    element.style.overflow = 'hidden';
    var collapsedHeight;
    if (element.innerText.length + 30 > textLimit) {
        truncText = element.innerHTML.slice(0, textLimit);
        // eslint-disable-next-line no-param-reassign
        element.innerHTML = truncText + "...";
        collapsedHeight = element.offsetHeight + "px";
        // eslint-disable-next-line no-param-reassign
        element.style.height = collapsedHeight;
        var btn_1 = document.createElement('button');
        btn_1.className = 'read-more-btn';
        btn_1.dataset.readMoreBtn = elementDataType;
        btn_1.innerHTML = "\n      <span class='read-more-btn__text' data-read-more-btn-text></span>\n      <svg class=\"read-more-btn__arrow\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n          <path d=\"M4 9L12 17L20 9\" stroke=\"currentColor\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n      </svg>\n    ";
        btn_1.querySelector('[data-read-more-btn-text]').textContent = btnTextMore;
        element.after(btn_1);
        btn_1.addEventListener('click', function (e) {
            e.stopPropagation();
            e.preventDefault();
            btn_1.classList.toggle('expanded');
            var btnTextContent = btn_1.querySelector('[data-read-more-btn-text]');
            if (btn_1.classList.contains('expanded')) {
                btnTextContent.textContent = btnTextLess;
                // eslint-disable-next-line no-param-reassign
                element.innerHTML = originalText;
                // eslint-disable-next-line no-param-reassign
                element.style.height = expandedHeight;
            }
            else {
                btnTextContent.textContent = btnTextMore;
                // eslint-disable-next-line no-param-reassign
                element.innerHTML = truncText + "...";
                // eslint-disable-next-line no-param-reassign
                element.style.height = collapsedHeight;
            }
        });
    }
}
