var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { initAndAnimate } from "./textAnimator";
function heightContainerExpertise(content) {
    var height = 0;
    content.forEach(function (item) {
        if (item.getBoundingClientRect().height > height)
            height = item.getBoundingClientRect().height;
    });
    document.querySelector('[data-expertise]').style.minHeight = Math.ceil(height) + "px";
}
export default function expertiseToggle() {
    var control = document.querySelectorAll('[data-expertise-control]');
    var content = document.querySelectorAll('[data-expertise-content]');
    var activeClassControl = 'expertise__controls-item--active';
    var activeClassContent = 'expertise__item--active';
    heightContainerExpertise(content);
    control.forEach(function (item) {
        item.addEventListener('click', function () {
            if (item.classList.contains(activeClassControl))
                return;
            __spread(control).find(function (_) { return _.classList.contains(activeClassControl); }).classList.remove(activeClassControl);
            item.classList.add(activeClassControl);
            __spread(content).find(function (_) { return _.classList.contains(activeClassContent); }).classList.remove(activeClassContent);
            var activeContent = __spread(content).find(function (_) { return _.dataset.expertiseContent === item.dataset.expertiseControl; });
            activeContent.classList.add(activeClassContent);
            initAndAnimate(activeContent);
        });
    });
}
