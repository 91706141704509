export var pageIsActive = function () { return (document.visibilityState === 'visible').toString(); };
var checkVisibility = function (videoNode) {
    var elementTopOffset = videoNode.offsetTop;
    var elementBottomOffset = videoNode.offsetHeight + elementTopOffset;
    var screenToOffset = window.scrollY;
    var screenBottomOffset = screenToOffset + window.innerHeight;
    if (elementTopOffset > screenToOffset && screenBottomOffset > elementBottomOffset) {
        return false;
    }
    return true;
};
export var generateStats = function (videoNode, progress) {
    var src = videoNode.currentSrc;
    var videoDuration = videoNode.duration;
    var time = videoNode.currentTime;
    var videoIsVisible = checkVisibility(videoNode);
    return videoIsVisible ? {
        src: src,
        duration: videoDuration,
        time: time,
        event: 'video-info',
        'streaming-protocol': 'mp4',
        'hls-info': 'non-hsl',
        'video-progress': progress,
        'video-is-visible': videoIsVisible,
    } : undefined;
};
