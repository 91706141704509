import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
export default function Ticker(el) {
    ScrollTrigger.matchMedia({
        '(min-width: 769px)': function () {
            var tickerContentWrapper = el.querySelector('[data-ticker-content]');
            var tickerContentItemSelector = '[data-ticker-content-item]';
            var text = el.querySelector(tickerContentItemSelector);
            tickerContentWrapper.appendChild(text.cloneNode(true));
            tickerContentWrapper.appendChild(text.cloneNode(true));
            tickerContentWrapper.appendChild(text.cloneNode(true));
            gsap.to(tickerContentWrapper, {
                x: -text.offsetWidth,
                repeat: -1,
                ease: 'linear',
                duration: 14,
            });
            gsap.timeline({
                scrollTrigger: {
                    trigger: el,
                    start: 'top bottom',
                    end: 'bottom top',
                    onUpdate: function (self) {
                        gsap.to(el, {
                            x: "-" + (self.progress * 40).toFixed(4) + "%",
                        });
                    },
                },
            });
        },
    });
}
