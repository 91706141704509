var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { isVisibleByY } from '../trackVisibilityWindow';
var LazyAutoPlay = /** @class */ (function () {
    function LazyAutoPlay() {
    }
    LazyAutoPlay.init = function () {
        var timer;
        var handler = function () {
            // Some elements are added dynamically, so we recheck every time
            var videos = __spread(document.querySelectorAll('video[data-lazy-autoplay]'));
            videos.forEach(function (v) {
                if (!v.autoplay && isVisibleByY(v)) {
                    // eslint-disable-next-line no-param-reassign
                    v.autoplay = true;
                    v.removeAttribute('data-lazy-autoplay');
                    v.play();
                }
            });
        };
        var check = function () {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(function () {
                handler();
            });
        };
        window.addEventListener('scroll', check);
        window.addEventListener('custom-scroll', check); // 'custom-scroll' is triggered in 'gsapSmoothScrollBar' and replaces native scroll
        window.addEventListener('resize', check);
        check();
    };
    return LazyAutoPlay;
}());
export { LazyAutoPlay };
