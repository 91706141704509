var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import lozad from 'lozad';
import { LazyAutoPlay } from './els/LazyAutoPlay';
import { LazyRecaptcha } from './els/LazyRecaptcha';
import menu from './els/menu';
import gsap from './els/gsapSmoothScrollBar';
import Accordion from './els/Accordion';
import { Preloader } from './els/Preloader';
import { Swiper, Nodes } from './els/Swiper';
import Form from './els/Form';
import AutosizeTextarea from './els/AutosizeTextarea';
import showreelFullscreen from './els/showreelFullscreen';
import locationsToggle from './els/locationsToggle';
import ReadMore from './els/ReadMore';
import LoadMore from './els/LoadMore';
import expertiseToggle from './els/expertiseToggle';
import videoPreview from './els/videoPreview';
import Ticker from './els/Ticker';
import ServicesOverlay from './els/ServicesOverlay';
import pageVideoChecker from './videoHandlers';
import ViewportHeight from './els/CalculateVh';
var instance = null;
/**
 * Handler for side menu logic
 */
var Main = /** @class */ (function () {
    /**
     * Constructor
     */
    function Main() {
        var _this = this;
        pageVideoChecker();
        this.els = {
            accordionNodes: __spread(document.querySelectorAll('[data-accordion]')),
            swiperNodes: Object.values(Nodes).reduce(function (arr, selector) { return __spread(arr, document.querySelectorAll("[" + selector + "]")); }, []),
            forms: __spread(document.querySelectorAll('[data-form-validation]')),
            videoPreview: __spread(document.querySelectorAll('[data-video-preview]')),
            textareaNodes: __spread(document.querySelectorAll('.js-textarea')),
            showreel: document.querySelector('[data-showreel]'),
            locationList: document.querySelector('[data-map-point]'),
            readMore: __spread(document.querySelectorAll('[data-read-more]')),
            loadMore: __spread(document.querySelectorAll('[data-load-more]')),
            expertiseControl: document.querySelector('[data-expertise-control]'),
            preserveImageProportions: __spread(document.querySelectorAll('[data-preserve_image_proportions]')),
            ticker: __spread(document.querySelectorAll('[data-ticker]')),
            servicesOverlay: __spread(document.querySelectorAll('[data-services-partner]')),
        };
        var preloader = new Preloader({});
        // @ts-ignore
        this.bodyScrollBar = gsap(preloader);
        menu(this.bodyScrollBar);
        var observer = lozad('.lozad');
        observer.observe();
        this.els.videoPreview.forEach(function (el) { return videoPreview(el); });
        this.els.accordionNodes.forEach(function (node) { return Accordion.init(node, _this.bodyScrollBar); });
        this.els.forms.forEach(function (form) { return Form.init(form, _this.bodyScrollBar); });
        this.els.textareaNodes.forEach(function (textarea) { return AutosizeTextarea.init(textarea); });
        this.els.preserveImageProportions.forEach(function (img) { var _a, _b; return Swiper.fixImageProportions(img, +((_a = img.dataset.maxHeight) !== null && _a !== void 0 ? _a : 100), (_b = img.dataset.maxHeightUnit) !== null && _b !== void 0 ? _b : 'vh'); });
        if (this.els.swiperNodes.length)
            Swiper.init(this.els.swiperNodes);
        if (this.els.showreel)
            showreelFullscreen();
        if (this.els.locationList)
            locationsToggle();
        if (this.els.readMore)
            this.els.readMore.forEach(function (el) { return ReadMore(el); });
        if (this.els.loadMore)
            this.els.loadMore.forEach(function (el) { return LoadMore(el, _this.bodyScrollBar); });
        if (this.els.expertiseControl)
            expertiseToggle();
        if (this.els.ticker)
            this.els.ticker.forEach(function (el) { return Ticker(el); });
        if (this.els.servicesOverlay)
            this.els.servicesOverlay.forEach(function (el) { return ServicesOverlay(el); });
        LazyAutoPlay.init();
        LazyRecaptcha.init();
        ViewportHeight.init();
    }
    /**
     * Run it
     * @return {Main}
     */
    Main.run = function () {
        if (!instance) {
            instance = new Main();
        }
        return instance;
    };
    return Main;
}());
export default Main;
