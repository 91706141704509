export default function menu(bodyScrollBar) {
    var bodyOverlayClass = 'body-overlay';
    var menuVisibleClass = 'menu--visible';
    var hamburgerActiveClass = 'hamburger--active';
    var body = document.querySelector('body');
    var hamburger = document.querySelector('[data-hamburger]');
    var menuEl = document.querySelector('.menu');
    var onClickMenuOutside = function (event) {
        if (!event.target.closest("." + menuVisibleClass) && !event.target.closest('[data-hamburger]')) {
            body.classList.remove(bodyOverlayClass);
            hamburger.classList.remove(hamburgerActiveClass);
            menuEl.classList.remove(menuVisibleClass);
            document.removeEventListener('click', onClickMenuOutside);
        }
    };
    hamburger.addEventListener('click', function () {
        body.classList.toggle(bodyOverlayClass);
        hamburger.classList.toggle(hamburgerActiveClass);
        menuEl.classList.toggle(menuVisibleClass);
        if (menuEl.classList.contains(menuVisibleClass))
            document.addEventListener('click', onClickMenuOutside);
    });
    var circle = document.querySelector('[data-hamburger-progress]');
    var radius = circle.getBoundingClientRect().width / 2;
    var circumference = radius * 2 * Math.PI;
    circle.style.strokeDasharray = circumference + " " + circumference;
    circle.style.strokeDashoffset = "" + circumference;
    bodyScrollBar.addListener(function (event) {
        var offset = circumference - (event.offset.y / event.limit.y) * circumference;
        circle.style.strokeDashoffset = offset;
    });
}
