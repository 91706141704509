var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import { Draggable } from 'gsap/Draggable';
import { isIPadPro, isMobile } from '../helpers';
import numberShadowAnimation from '../animations/numberShadowAnimation';
import { CursorController } from './CursorController';
import { lineAnimationWrapperSelector, animateTexts, trackTextsToAnimate, initText, } from './textAnimator';
gsap.registerPlugin(ScrollTrigger, CSSRulePlugin, Draggable);
gsap.config({
    nullTargetWarn: false,
});
/**
 * @param {Preloader} preloader
 * @returns {Scrollbar|number}
 */
export default function gsapSmoothScrollBar(preloader) {
    var MobilePlugin = /** @class */ (function (_super) {
        __extends(MobilePlugin, _super);
        function MobilePlugin() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        MobilePlugin.prototype.transformDelta = function (delta, fromEvent) {
            if (fromEvent.type !== 'touchend') {
                return delta;
            }
            return {
                x: delta.x * this.options.speed,
                y: delta.y * this.options.speed,
            };
        };
        MobilePlugin.pluginName = 'mobile';
        MobilePlugin.defaultOptions = {
            speed: 0.5,
        };
        return MobilePlugin;
    }(ScrollbarPlugin));
    Scrollbar.use(MobilePlugin);
    var scroller = document.querySelector('[data-main-scrollbar]');
    var bodyScrollBar = Scrollbar.init(scroller, {
        delegateTo: document,
        continuousScrolling: false,
        plugins: {
            mobile: {
                speed: 0.5,
            },
        },
    });
    bodyScrollBar.addListener(function (scrollEvent) {
        window.dispatchEvent(new CustomEvent('custom-scroll', {
            detail: scrollEvent,
        }));
    });
    ScrollTrigger.scrollerProxy(scroller, {
        scrollTop: function (value) {
            if (arguments.length) {
                bodyScrollBar.scrollTop = value;
            }
            return bodyScrollBar.scrollTop;
        },
    });
    var limitY = 0;
    bodyScrollBar.addListener(function (status) {
        ScrollTrigger.update();
        // refresh ScrollTrigger after change height of document
        if (status.limit.y !== limitY) {
            ScrollTrigger.refresh();
        }
        limitY = status.limit.y;
    });
    ScrollTrigger.defaults({ scroller: scroller });
    var scrollButtons = document.querySelectorAll('[data-scroll-to]');
    if (scrollButtons.length > 0) {
        scrollButtons.forEach(function (item) {
            var targetSection = item.dataset.scrollTo;
            item.addEventListener('click', function () {
                bodyScrollBar.scrollIntoView(document.querySelector("[" + targetSection + "]"));
            });
        });
    }
    /**
     * Text line by line animation
     */
    trackTextsToAnimate(__spread(document.querySelectorAll(lineAnimationWrapperSelector)));
    /**
     * horizontalSections
     */
    if (document.querySelector('[data-horizontal-block]') && !isMobile()) {
        var horizontalBlock = document.querySelector('[data-horizontal-block]');
        var horizontalSlider_1 = horizontalBlock.querySelector('[data-horizontal-slider]');
        gsap.fromTo(horizontalSlider_1, {
            x: 0,
        }, {
            x: -(horizontalSlider_1.scrollWidth - window.innerWidth),
            ease: 'none',
            scrollTrigger: {
                trigger: horizontalBlock,
                pinType: 'transform',
                start: 'center center',
                end: function () { return "+=" + horizontalSlider_1.scrollWidth; },
                pin: horizontalBlock,
                invalidateOnRefresh: true,
                anticipatePin: 1,
                scrub: true,
            },
        });
    }
    ScrollTrigger.matchMedia({
        // eslint-disable-next-line func-names
        '(max-width: 1024px)': function () {
            /**
             * header scroll
             */
            var pageHeader = document.querySelector('[data-header]');
            if (pageHeader) {
                bodyScrollBar.addListener(function (_a) {
                    var offset = _a.offset;
                    if (offset.y > 68) {
                        pageHeader.classList.add('header--scroll');
                    }
                    else {
                        pageHeader.classList.remove('header--scroll');
                    }
                });
            }
        },
        '(min-width: 1025px)': function () {
            /**
             * services carousel
             */
            if (document.querySelector('[data-services]')) {
                var servicesTrackEl = document.querySelector('[data-services-track]');
                var trackLeftEl_1 = document.querySelector('[data-services-track-list]');
                var trackRightEl_1 = document.querySelector('[data-services-track-list-right]');
                gsap.fromTo(trackLeftEl_1, {
                    y: 0,
                }, {
                    y: -(trackLeftEl_1.scrollHeight - trackLeftEl_1.clientHeight),
                    ease: 'none',
                    scrollTrigger: {
                        trigger: trackLeftEl_1,
                        start: 'center center',
                        end: function () { return "+=" + (trackLeftEl_1.scrollHeight - trackLeftEl_1.clientHeight); },
                        onUpdate: function (self) {
                            gsap.set(trackRightEl_1, {
                                y: (trackLeftEl_1.scrollHeight - trackLeftEl_1.clientHeight) * self.progress,
                            });
                        },
                        invalidateOnRefresh: true,
                        anticipatePin: 1,
                        scrub: true,
                        pinSpacing: true,
                        pin: servicesTrackEl,
                    },
                });
            }
        },
        '(min-width: 993px)': function () {
            /**
             * privacy policy
             */
            if (document.querySelector('.privacy__item-head')) {
                gsap.utils.toArray('.privacy__item').forEach(function (box) {
                    var pinElement = box.querySelector('.privacy__item-head');
                    ScrollTrigger.create({
                        trigger: box,
                        pin: pinElement,
                        start: 'top 15%',
                        end: "+=" + (box.getBoundingClientRect().height - pinElement.getBoundingClientRect().height),
                    });
                });
                gsap.utils.toArray('.privacy__item').forEach(function (box) {
                    var counter = box.querySelector('.privacy__item-counter');
                    var title = box.querySelector('.privacy__item-title');
                    gsap.timeline({
                        scrollTrigger: {
                            trigger: box,
                            start: 'top 90%',
                        },
                    })
                        .from(counter, {
                        opacity: 0,
                        yPercent: 80,
                        duration: 0.5,
                    })
                        .from(title, {
                        opacity: 0,
                        yPercent: 40,
                        duration: 0.5,
                    }, '-=0.2');
                });
            }
        },
        // eslint-disable-next-line func-names
        '(min-width: 769px)': function () {
            /**
             * geometry animate path
             */
            if (document.querySelector('[data-geometry-figure]')) {
                var figures = Array.from(document.querySelectorAll('[data-geometry-figure]'));
                /**
                 * Figure animation in seconds
                 * @type {number}
                 */
                var duration_1 = 3;
                /**
                 * Flags if specific figure is animated
                 * @type {Record<string, boolean>}
                 */
                var animated_1 = {};
                /* Note we are avoiding negative dash outset for safari */
                figures
                    .forEach(function (item, index) {
                    var length = item === null || item === void 0 ? void 0 : item.getTotalLength();
                    if (length) {
                        item.setAttribute('style', "stroke-dasharray:" + length + " " + length + "; stroke-dashoffset: " + 3 * length + ";");
                        item.setAttribute('data-geometry-id', "figure-" + index);
                        animated_1["figure-" + index] = false;
                    }
                });
                var scheduleAnimation_1 = function () {
                    // Pick random figure that's not animated
                    var candidates = Object.keys(animated_1).filter(function (id) { return !animated_1[id]; });
                    var nonAnimatedCount = candidates.length;
                    var animatedCount = Object.keys(animated_1).length - nonAnimatedCount;
                    /**
                     * This check targets browser suppressing timers when window is not in focus
                     */
                    if (animatedCount < 2 && nonAnimatedCount > 0) {
                        var chosenOne_1 = candidates[Math.floor(Math.random() * candidates.length)];
                        var element = document.querySelector("[data-geometry-id=\"" + chosenOne_1 + "\"]");
                        var length = element === null || element === void 0 ? void 0 : element.getTotalLength();
                        element.setAttribute('style', "stroke-dasharray:" + length + " " + length + "; stroke-dashoffset: " + 3 * length + ";");
                        animated_1[chosenOne_1] = true;
                        gsap.to("[data-geometry-id=\"" + chosenOne_1 + "\"]", {
                            strokeDashoffset: function (index, target) { return target.getTotalLength(); },
                            ease: 'power1.in',
                            duration: duration_1,
                        }).eventCallback('onComplete', function () {
                            animated_1[chosenOne_1] = false;
                            // Typically this wont do anything, since timer will start animation
                            // However browser may suppress timer, so this is a backup to ensure animation restarts
                            scheduleAnimation_1();
                        });
                    }
                    var timeout = duration_1 * 1000 * (0.5 + Math.random() * 0.1);
                    setTimeout(scheduleAnimation_1, timeout);
                };
                scheduleAnimation_1();
            }
            /**
             * error page
             */
            if (document.querySelector('[data-error-content-head]')) {
                var errorPage = gsap.timeline();
                errorPage
                    .from('[data-error-content-head]', {
                    opacity: 0,
                    yPercent: 50,
                    duration: 0.7,
                })
                    .from('[data-error-content-text]', {
                    opacity: 0,
                    yPercent: 100,
                    duration: 0.7,
                }, '-=0.2')
                    .from('[data-error-status]', {
                    opacity: 0,
                    yPercent: 40,
                    duration: 0.7,
                }, '-=0.2')
                    .from('[data-error-nav] > *', {
                    opacity: 0,
                    yPercent: 100,
                    duration: 1,
                    stagger: 0.1,
                })
                    .from('[data-error-geometry]', {
                    opacity: 0,
                    duration: 0.5,
                });
            }
            /**
             * expertise
             */
            if (document.querySelector('[data-expertise-control]')) {
                gsap.timeline({
                    scrollTrigger: {
                        trigger: '[data-expertise]',
                        start: 'top 85%',
                    },
                })
                    .from('.expertise__controls-item-arrow', {
                    scaleX: 0,
                    stagger: 0.1,
                });
            }
            /**
             * cursor
             */
            if (!isMobile() && !isIPadPro()) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                var cursor = new CursorController(gsap);
            }
        },
        all: function () {
            /**
             * appearanceBox
             */
            preloader.addOnDoneHandler(function () {
                var appearanceBox = gsap.utils.toArray('[data-appearance]');
                appearanceBox.forEach(function (box) {
                    var texts = initText(box);
                    gsap.from(box, {
                        opacity: 0,
                        y: 50,
                        duration: 1.5,
                        scrollTrigger: {
                            trigger: box,
                            start: 'top 85%',
                        },
                    }).eventCallback('onStart', function () {
                        animateTexts(texts);
                    });
                });
            });
            /**
             * client logos animation
             */
            if (document.querySelector('[data-clients-logos-ticker]')) {
                document.querySelectorAll('[data-clients-logos-ticker]').forEach(function (ticker) {
                    var tickerItem = ticker.querySelector('[data-clients-logos-ticker-item]');
                    var tickerItemWidth = tickerItem.offsetWidth;
                    /**
                     * We need logos to run smoothly from right to left like a ticker (marquee).
                     * At the same time we can't just use <marquee> tag as we need more fine graded
                     * control on the ticker behaviour. So, the marquee like appearance is achieved by
                     * repeating logos list in the DOM by cloning it and appending to the parent element.
                     */
                    var minItemsCount = Math.round(Math.max(window.screen.width, window.screen.height) / tickerItem.offsetWidth) * 2;
                    for (var i = 0; i <= minItemsCount; i += 1) {
                        ticker.append(tickerItem.cloneNode(true));
                    }
                    var loopDuration = 48;
                    var pausedAt;
                    var tickerTimeline = gsap.timeline({
                        scrollTrigger: {
                            trigger: ticker,
                        },
                    });
                    tickerTimeline
                        .fromTo(ticker, {
                        x: -tickerItemWidth * (Math.round(minItemsCount / 2)),
                    }, {
                        x: -tickerItemWidth * (Math.round(minItemsCount / 2) + 1),
                        duration: loopDuration,
                        ease: 'linear',
                        repeat: -1,
                    });
                    var logosTextTimeline = gsap.timeline({
                        scrollTrigger: {
                            trigger: ticker,
                        },
                    });
                    logosTextTimeline
                        .from('[data-clients-logo-line]', {
                        scaleX: 0,
                        duration: 2,
                    }, '<')
                        .from('[data-clients-logo-line-text]', {
                        opacity: 0,
                    }, '-=.3');
                    Draggable.create(ticker, {
                        type: 'x',
                        onPressInit: function () {
                            pausedAt = tickerTimeline.time();
                            tickerTimeline.pause();
                        },
                        onClick: function () {
                            if (!this.endX) {
                                tickerTimeline.play();
                            }
                        },
                        onRelease: function () {
                            if (this.endX && this.startX) {
                                /* Measure drag distance */
                                var dragShift = this.endX - this.startX;
                                /**
                                 * If measured drag distance is greater than X coordinate shift during a single
                                 * animation loop we should take into account only remainder. This will ensure
                                 * that timeShift that is calulated below is less than loop duration and amimation
                                 * start time is not negative value.
                                 */
                                if (Math.abs(dragShift) > tickerItemWidth) {
                                    dragShift %= tickerItemWidth;
                                }
                                /**
                                 * Calculate a time interval we need to adjust timeline to keep animation in sync with drag.
                                 * When dragging LTR this will be positive value and negative otherwise.
                                 */
                                var timeShift = loopDuration * (dragShift / tickerItemWidth);
                                /* Calculate a time from which animation have to be started */
                                var startAt = pausedAt - timeShift;
                                /**
                                 * If we start drag LTR when animation have just start running, we can get negative calculated
                                 * start time. This will create a corresponding pause in animation. To prevent this we can just
                                 * start at a calculated time before next animation loop
                                 */
                                if (startAt < 0) {
                                    startAt = loopDuration + startAt;
                                }
                                tickerTimeline.seek(startAt);
                                if (dragShift > 0) {
                                    tickerTimeline.reverse();
                                }
                                else {
                                    tickerTimeline.play();
                                }
                            }
                        },
                    });
                });
            }
            /**
             * slideInContent
             */
            if (document.querySelector('[data-slide-in-from-right]')) {
                gsap.utils.toArray('[data-slide-in-from-right]').forEach(function (box) {
                    var duration = +(box.dataset.slideInFromRight || '1');
                    gsap.set(box, {
                        translateX: '20%',
                    });
                    gsap.to(box, {
                        translateX: '0%',
                        duration: duration,
                        scrollTrigger: {
                            trigger: box,
                        },
                    });
                });
            }
            /**
             * contact form
             */
            if (document.querySelector('[data-form-group]')) {
                gsap.timeline({
                    scrollTrigger: {
                        trigger: '[data-contact-form]',
                        start: 'top bottom',
                    },
                })
                    .from('[data-form-group]', {
                    opacity: 0,
                    yPercent: 10,
                    stagger: 0.3,
                })
                    .from('[data-form-group-border]', {
                    scaleX: 0,
                    ease: 'back.out(1.9)',
                    stagger: 0.1,
                });
            }
            /**
             * parallax
             */
            if (document.querySelector('[data-parallax-box]')) {
                gsap.utils.toArray('[data-parallax-box]').forEach(function (box) {
                    var parallaxContent = box.querySelector('[data-parallax-content]');
                    var parallaxSize = parallaxContent.dataset.parallaxSize || 30;
                    var parallaxDirection = parallaxContent.dataset.parallaxDirection;
                    if (parallaxDirection && box.offsetHeight > window.innerHeight) {
                        parallaxContent.style.paddingTop = box.offsetHeight - window.innerHeight + "px";
                    }
                    parallaxContent.style.transform = "translateY(-" + parallaxSize + "%)";
                    gsap.to(parallaxContent, {
                        translateY: (parallaxDirection === 'top' ? 0 : parallaxSize) + "%",
                        ease: 'none',
                        scrollTrigger: __assign({ trigger: box, scrub: true }, (parallaxDirection && {
                            start: 'top bottom',
                            end: 'bottom bottom',
                        })),
                    });
                });
            }
        },
    });
    if (document.querySelector('[data-homepage-intro]')) {
        ScrollTrigger.create({
            trigger: '[data-homepage-intro]',
            start: 'top top',
            onEnter: function () {
                bodyScrollBar.addListener(function (_a) {
                    var offset = _a.offset;
                    if (offset.y > 50) {
                        gsap.timeline()
                            .to('[data-header-logo]', {
                            opacity: 1,
                            duration: 1.5,
                        });
                    }
                    else {
                        gsap.timeline()
                            .to('[data-header-logo]', {
                            opacity: 1,
                            duration: 1.5,
                        });
                    }
                });
            },
        });
        var showreelBounce_1 = document.querySelector('[data-showreel-bounce]');
        if (showreelBounce_1) {
            var showreelBounceHeight_1 = showreelBounce_1.getBoundingClientRect().height;
            bodyScrollBar.addListener(function (_a) {
                var offset = _a.offset;
                showreelBounce_1.style.cssText += "transition: none;top: calc(100vh - " + showreelBounceHeight_1 * 1.25 + "px + " + offset.y + "px)";
            });
        }
    }
    else {
        bodyScrollBar.addListener(function (_a) {
            var offset = _a.offset;
            if (offset.y > 50) {
                gsap.timeline()
                    .to('[data-header-logo]', {
                    opacity: 1,
                    duration: 1.5,
                });
            }
            else {
                gsap.timeline()
                    .to('[data-header-logo]', {
                    opacity: 1,
                    duration: 1.5,
                });
            }
        });
    }
    ScrollTrigger.create({
        trigger: document.querySelector('[data-offices-scroll-trigger]'),
        start: 'top center',
        once: true,
        onEnter: function () {
            numberShadowAnimation('[data-offices-scroll-trigger] [data-number-shadow-animation]');
        },
    });
    ScrollTrigger.create({
        trigger: document.querySelector('[data-project-performance-scroll-trigger]'),
        start: 'top bottom',
        once: true,
        onEnter: function () {
            numberShadowAnimation('[data-project-performance-scroll-trigger] [data-number-shadow-animation]');
        },
    });
    ScrollTrigger.create({
        trigger: document.querySelector('[data-quick-facts-trigger]'),
        start: 'top bottom',
        once: true,
        onEnter: function () {
            numberShadowAnimation('[data-quick-facts-trigger] [data-number-shadow-animation]');
        },
    });
    ScrollTrigger.create({
        trigger: document.querySelector('[data-service-facts-trigger]'),
        start: 'top bottom',
        once: true,
        onEnter: function () {
            numberShadowAnimation('[data-service-facts-trigger] [data-number-shadow-animation]');
        },
    });
    return bodyScrollBar;
}
