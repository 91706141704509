var ViewportHeight = /** @class */ (function () {
    function ViewportHeight() {
        var _this = this;
        this.updateVh();
        window.addEventListener('resize', function () {
            _this.updateVh();
        });
        window.addEventListener('orientationchange', function () {
            _this.updateVh();
        });
    }
    ViewportHeight.init = function () {
        return new ViewportHeight();
    };
    // eslint-disable-next-line class-methods-use-this
    ViewportHeight.prototype.updateVh = function () {
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', vh + "px");
    };
    return ViewportHeight;
}());
export default ViewportHeight;
