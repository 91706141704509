import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
export default function ServicesOverlay(el) {
    var servicesPartnerOverlay = '[data-services-partner-overlay]';
    var heightOverlay = document.querySelector(servicesPartnerOverlay);
    ScrollTrigger.create({
        trigger: el,
        pin: servicesPartnerOverlay,
        start: "top-=" + (window.innerHeight - heightOverlay.getBoundingClientRect().height) + " top",
        end: 'bottom bottom',
        onUpdate: function (self) {
            if (self.end < self.scroll()) {
                gsap.to(servicesPartnerOverlay, {
                    opacity: 0,
                });
            }
            if (self.start > self.scroll()) {
                gsap.to(servicesPartnerOverlay, {
                    opacity: 1,
                });
            }
        },
    });
}
