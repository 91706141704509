var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
/**
 * @param swiper
 * @param {HTMLElement} el
 */
export default function historySwiper(swiper, el) {
    var slideWrapEl = el.firstElementChild;
    var slides = __spread(slideWrapEl === null || slideWrapEl === void 0 ? void 0 : slideWrapEl.children);
    var swipePrevSelector = '.swiper-slide-prev';
    var swipeNextSelector = '.swiper-slide-next';
    var swipeActiveClass = 'swiper-slide-active';
    var arrowUp = document.querySelector('[data-slider-arrow-up]');
    var arrowDown = document.querySelector('[data-slider-arrow-down]');
    function arrowClickHandler(isNext) {
        var _a, _b, _c, _d;
        var currentSelector = isNext ? swipeNextSelector : swipePrevSelector;
        var curentEl = slideWrapEl === null || slideWrapEl === void 0 ? void 0 : slideWrapEl.querySelector(currentSelector);
        var activeEl = slideWrapEl === null || slideWrapEl === void 0 ? void 0 : slideWrapEl.querySelector("." + swipeActiveClass);
        function clearingClasses() {
            [activeEl, activeEl === null || activeEl === void 0 ? void 0 : activeEl.nextElementSibling, activeEl === null || activeEl === void 0 ? void 0 : activeEl.previousElementSibling, curentEl]
                .forEach(function (el) {
                [swipeNextSelector.substring(1), swipePrevSelector.substring(1), swipeActiveClass]
                    .forEach(function (_) { return el === null || el === void 0 ? void 0 : el.classList.remove(_); });
            });
        }
        clearingClasses();
        curentEl === null || curentEl === void 0 ? void 0 : curentEl.classList.add(swipeActiveClass);
        var indx = slides.indexOf(curentEl);
        if (!curentEl) {
            curentEl = activeEl.nextElementSibling;
            indx = slides.indexOf(curentEl);
        }
        else if (indx < 4) {
            swiper.slideToLoop(indx);
        }
        if (indx >= 4) {
            (_a = slides[indx - 1]) === null || _a === void 0 ? void 0 : _a.classList.add(swipePrevSelector.substring(1));
            (_b = slides[indx - 1]) === null || _b === void 0 ? void 0 : _b.classList.remove(swipeActiveClass);
            arrowUp.removeAttribute('disabled');
        }
        else if (indx === 0) {
            arrowUp.setAttribute('disabled', '');
        }
        if (indx >= 4 && indx + 1 < slides.length) {
            (_c = slides[indx + 1]) === null || _c === void 0 ? void 0 : _c.classList.add(swipeNextSelector.substring(1));
            (_d = slides[indx + 1]) === null || _d === void 0 ? void 0 : _d.classList.remove(swipeActiveClass);
            arrowDown.removeAttribute('disabled');
        }
        else if (indx + 1 === slides.length) {
            arrowDown.setAttribute('disabled', '');
        }
    }
    arrowUp.addEventListener('click', function () { return arrowClickHandler(); });
    arrowDown.addEventListener('click', function () { return arrowClickHandler(true); });
    swiper.on('slideChangeTransitionEnd', function () {
        var indx = slides.indexOf(slideWrapEl === null || slideWrapEl === void 0 ? void 0 : slideWrapEl.querySelector("." + swipeActiveClass));
        if (indx === 0) {
            arrowUp.setAttribute('disabled', '');
        }
        else {
            arrowUp.removeAttribute('disabled');
        }
        if (indx === slides.length - 4) {
            arrowDown.setAttribute('disabled', '');
        }
        else {
            arrowDown.removeAttribute('disabled');
        }
    });
}
