export default function LoadMore(element, bodyScrollBar) {
    var works = document.querySelectorAll('[data-our-work]');
    var parent = works[0].parentNode;
    var countWorkGroup = 0;
    var countVisibleGroup = 1;
    /**
     * Generate group of work
     */
    function createGroup(work, i) {
        var newDiv = document.createElement('div');
        newDiv.classList.add('our-work__group');
        parent === null || parent === void 0 ? void 0 : parent.appendChild(newDiv);
        newDiv.appendChild(works[i]);
        if (i !== 0) {
            newDiv.classList.add('hide');
        }
    }
    works.forEach(function (work, i) {
        if (i % 4 === 0) {
            createGroup(work, i);
            countWorkGroup++;
        }
        else {
            var workGroup = parent === null || parent === void 0 ? void 0 : parent.querySelector('.our-work__group:last-of-type');
            workGroup === null || workGroup === void 0 ? void 0 : workGroup.appendChild(works[i]);
        }
    });
    if (countWorkGroup < 2) {
        element.classList.add('hide');
    }
    /**
     * Listeners for button Load
     */
    var workGroupList = document.querySelectorAll("[data-load-more-content=" + element.dataset.loadMore + "] .our-work__group");
    function handleClick() {
        if (countWorkGroup > countVisibleGroup) {
            workGroupList[countVisibleGroup].classList.remove('hide');
            countVisibleGroup++;
        }
        else {
            workGroupList.forEach(function (item, index) {
                if (index > 0) {
                    item.classList.add('hide');
                }
            });
            bodyScrollBar === null || bodyScrollBar === void 0 ? void 0 : bodyScrollBar.scrollIntoView(element, {
                alignToTop: false,
                offsetBottom: 50,
                onlyScrollIfNeeded: true,
            });
            countVisibleGroup = 1;
        }
        if (countVisibleGroup === 1) {
            // eslint-disable-next-line no-param-reassign
            element.textContent = 'load more';
        }
        if (countVisibleGroup === countWorkGroup) {
            // eslint-disable-next-line no-param-reassign
            element.textContent = 'load less';
        }
    }
    element.addEventListener('click', handleClick);
}
