export default function locationsToggle() {
    var mapPointList = document.querySelectorAll('[data-map-point]');
    var activePointClass = 'map__point--active';
    mapPointList.forEach(function (item) {
        item.addEventListener('click', function () {
            if (item.classList.contains(activePointClass))
                return;
            mapPointList.forEach(function (location) { return location.classList.remove(activePointClass); });
            item.classList.add(activePointClass);
        });
    });
}
