var AutosizeTextarea = /** @class */ (function () {
    function AutosizeTextarea(el) {
        if (!el)
            return;
        this.textarea = el;
        this.handlerTextarea = this.handlerTextarea.bind(this);
        this.handlerTextarea();
    }
    AutosizeTextarea.init = function (el) {
        return new AutosizeTextarea(el);
    };
    AutosizeTextarea.prototype.handlerTextarea = function () {
        var _this = this;
        this.textarea.setAttribute('style', "height: " + this.textarea.scrollHeight + "px");
        this.textarea.addEventListener('input', function () {
            _this.textarea.style.height = '46px';
            _this.textarea.style.height = _this.textarea.scrollHeight + "px";
        });
    };
    return AutosizeTextarea;
}());
export default AutosizeTextarea;
