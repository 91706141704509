import { gsap } from 'gsap';
import Splitting from 'splitting';
import 'splitting/dist/splitting.css';
export default function numberShadowAnimation(targetSelector) {
    if (document.querySelector(targetSelector)) {
        gsap.utils.toArray(document.querySelectorAll(targetSelector))
            .forEach(function (item) {
            var _a, _b, _c, _d;
            var innerHTML = item.innerHTML, textContent = item.textContent;
            item.style.position = 'relative'; // eslint-disable-line no-param-reassign
            item.style.overflow = 'hidden'; // eslint-disable-line no-param-reassign
            /**
             * We need to animate not only text itself, but also its shadow like
             * double. So at first we have to create that double, wrap both original
             * text and its double in separate <span>s and apply some styles.
             */
            item.textContent = ''; // eslint-disable-line no-param-reassign
            var text = document.createElement('span');
            text.style.display = 'block';
            text.textContent = textContent;
            item.appendChild(text);
            var shadowText = document.createElement('span');
            shadowText.style.display = 'block';
            shadowText.style.opacity = '0.2';
            shadowText.style.position = 'absolute';
            shadowText.style.top = '0px';
            shadowText.style.left = '0px';
            shadowText.textContent = textContent;
            item.appendChild(shadowText);
            /**
             * Split text into individual words/characters to be able to animate
             * them individually. TODO: consider to change our SplitText
             * (front\src\SplitText.ts) implementation to support splitting by
             * characters and use it instead of 3rd party Splitting library (or vice
             * versa). But the best option IMHO is to use SplitText plugin for GSAP (paid).
             */
            var chars = (_b = (_a = Splitting({ target: text })) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.chars;
            var shadowChars = (_d = (_c = Splitting({ target: shadowText })) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.chars;
            var distance = item.offsetHeight;
            gsap.set([shadowChars, chars], { y: distance });
            var timeline = gsap.timeline({
                delay: 0.6,
                onComplete: function () {
                    /* Restore item content to it's initial state (plain text) */
                    item.innerHTML = innerHTML; // eslint-disable-line no-param-reassign
                },
            });
            timeline
                .to(shadowChars, {
                y: -distance,
                stagger: {
                    each: 0.07,
                },
                ease: 'expo',
                duration: 1.4,
            })
                .to(chars, {
                y: 0,
                stagger: {
                    each: 0.07,
                },
                ease: 'expo',
                duration: 1.4,
            }, '<');
        });
    }
}
