var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import SwiperCore, { Navigation, EffectFade, Pagination, Autoplay, Lazy, } from 'swiper/core';
import { isIPadPro, isMobile } from '../helpers';
import historySwiper from './historySwiper';
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay, Lazy]);
export var Nodes = {
    overflow: 'data-slider-overflow',
    randomWidth: 'data-slider-random-width',
    review: 'data-slider-reviews',
    expertise: 'data-expertise-slider',
    horizontal: 'data-horizontal-slider',
    vertical: 'data-slider-vertical',
    legacy: 'data-legacy-slider',
    pagination: 'data-slider-with-pagination',
};
var Swiper = /** @class */ (function () {
    /**
     * Constrcutor
     */
    function Swiper(nodes) {
        var _this = this;
        nodes.forEach(function (node) {
            if (!node.hasAttribute)
                return;
            if (node === null || node === void 0 ? void 0 : node.hasAttribute(Nodes.overflow))
                _this.sliderOverflow(node);
            if (node === null || node === void 0 ? void 0 : node.hasAttribute(Nodes.randomWidth))
                _this.sliderRandomWidth(node);
            if (node === null || node === void 0 ? void 0 : node.hasAttribute(Nodes.review))
                _this.sliderReview(node);
            if (node === null || node === void 0 ? void 0 : node.hasAttribute(Nodes.expertise))
                _this.sliderExpertise(node);
            if ((node === null || node === void 0 ? void 0 : node.hasAttribute(Nodes.horizontal)) && isMobile())
                _this.sliderHorizontal(node);
            if ((node === null || node === void 0 ? void 0 : node.hasAttribute(Nodes.vertical)) && !isMobile() && !isIPadPro())
                _this.sliderVertical(node);
            if (node === null || node === void 0 ? void 0 : node.hasAttribute(Nodes.legacy))
                _this.sliderOurLegacy(node);
            if (node === null || node === void 0 ? void 0 : node.hasAttribute(Nodes.pagination))
                _this.sliderReviewWithPagination(node);
        });
    }
    /**
     * Run it
     * @return {Swiper}
     */
    Swiper.init = function (nodes) {
        return new Swiper(nodes);
    };
    /**
     * Initial overflow slider
     * @param {HTMLElement} el
     */
    Swiper.prototype.sliderOverflow = function (el) {
        var parentElement = el.closest('[data-slider-with-navigation]');
        return new SwiperCore(el, {
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                },
                1300: {
                    slidesPerView: 2,
                    spaceBetween: 32,
                    watchOverflow: true,
                },
            },
            updateOnWindowResize: true,
            navigation: {
                nextEl: parentElement === null || parentElement === void 0 ? void 0 : parentElement.querySelector('[data-next-slide]'),
                prevEl: parentElement === null || parentElement === void 0 ? void 0 : parentElement.querySelector('[data-prev-slide]'),
            },
        });
    };
    /**
     * @param {HTMLImageElement} img
     * @returns {Promise<{width: number, height: number}>}
     */
    Swiper.getImageDimension = function (img) {
        return new Promise(function (resolve, reject) {
            if (img.naturalHeight && img.naturalWidth) {
                resolve({ width: img.naturalWidth, height: img.naturalHeight });
            }
            else {
                img.addEventListener('load', function () {
                    resolve({ width: img.naturalWidth, height: img.naturalHeight });
                });
                img.addEventListener('error', function () {
                    reject();
                });
            }
        });
    };
    /**
     * Fix image proportions inside div to match specific view height
     * @param {HTMLElement} div
     * @param {number} maxHeight
     * @param {string} maxHeightUnit
     */
    Swiper.fixImageProportions = function (div, maxHeight, maxHeightUnit) {
        if (maxHeight === void 0) { maxHeight = 94; }
        if (maxHeightUnit === void 0) { maxHeightUnit = 'vh'; }
        return __awaiter(this, void 0, void 0, function () {
            var images, dimensions, max;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        images = __spread(div.querySelectorAll('img'));
                        return [4 /*yield*/, Promise.all(images.map(function (img) { return Swiper.getImageDimension(img); }))];
                    case 1:
                        dimensions = _a.sent();
                        max = 0;
                        dimensions.forEach(function (d) { return max = Math.max(max, d.height); });
                        if (max) {
                            images.forEach(function (img) {
                                img.style.maxHeight = "" + (maxHeight * img.naturalHeight / max) + maxHeightUnit;
                            });
                        }
                        else {
                            console.error('No max height for images detected');
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Initial random width slider
     * @param {HTMLElement} el
     */
    Swiper.prototype.sliderRandomWidth = function (el) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Swiper.fixImageProportions(el)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, new SwiperCore(el, {
                                breakpoints: {
                                    0: {
                                        slidesPerView: 1,
                                        spaceBetween: 23,
                                    },
                                    768: {
                                        slidesPerView: 'auto',
                                        spaceBetween: 23,
                                    },
                                    1280: {
                                        slidesPerView: 'auto',
                                        spaceBetween: 32,
                                    },
                                },
                                updateOnWindowResize: true,
                            })];
                }
            });
        });
    };
    /**
     * Initial review slider
     * @param {HTMLElement} el
     */
    Swiper.prototype.sliderReview = function (el) {
        var parentElement = el.closest('[data-slider-with-navigation]');
        // eslint-disable-next-line no-new
        new SwiperCore(el, {
            speed: 500,
            effect: 'fade',
            autoHeight: true,
            initialSlide: 0,
            fadeEffect: {
                crossFade: true,
            },
            navigation: {
                nextEl: parentElement.querySelector('[data-next-slide]'),
                prevEl: parentElement.querySelector('[data-prev-slide]'),
            },
            updateOnWindowResize: true,
        });
    };
    /**
     * Initial expertise slider
     * @param {HTMLElement} el
     */
    Swiper.prototype.sliderExpertise = function (el) {
        var breakpoint = window.matchMedia('(min-width:48.0625em)');
        var expertiseSlider;
        var enableDomainSlider = function () {
            expertiseSlider = new SwiperCore(el, {
                slidesPerView: 1.3,
                spaceBetween: 14,
            });
            expertiseSlider.on('slideChangeTransitionEnd', function () {
                window.dispatchEvent(new CustomEvent('custom-swipe-end'));
            });
            window.dispatchEvent(new CustomEvent('custom-updatesplittext', {}));
        };
        var breakpointChecker = function () {
            if (breakpoint.matches) {
                expertiseSlider === null || expertiseSlider === void 0 ? void 0 : expertiseSlider.destroy(true, true);
            }
            else {
                enableDomainSlider();
            }
        };
        breakpointChecker();
        if (breakpoint === null || breakpoint === void 0 ? void 0 : breakpoint.addEventListener) {
            breakpoint.addEventListener('change', breakpointChecker);
        }
        else if (breakpoint === null || breakpoint === void 0 ? void 0 : breakpoint.addListener) {
            breakpoint === null || breakpoint === void 0 ? void 0 : breakpoint.addListener('change', breakpointChecker);
        }
    };
    /**
     * Initial horizontal slider
     * @param {HTMLElement} el
     */
    Swiper.prototype.sliderHorizontal = function (el) {
        el.classList.add('swiper-container');
        el.querySelector('[data-horizontal-container]').classList.add('swiper-wrapper');
        el.querySelectorAll('[data-horizontal-slide]').forEach(function (_) { return _.classList.add('swiper-slide'); });
        var swiper = new SwiperCore(el, {
            breakpoints: {
                0: {
                    slidesPerView: 1.1,
                    spaceBetween: 24,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 24,
                },
                1280: {
                    spaceBetween: 32,
                },
            },
            updateOnWindowResize: true,
        });
        swiper.on('slideChangeTransitionEnd', function () {
            window.dispatchEvent(new CustomEvent('custom-swipe-end'));
        });
    };
    /**
     * Initial vertical slider
     * @param {HTMLElement} el
     */
    Swiper.prototype.sliderVertical = function (el) {
        var swiper = new SwiperCore(el, {
            direction: 'vertical',
            initialSlide: 0,
            slidesPerView: 4,
            updateOnWindowResize: true,
        });
        historySwiper(swiper, el);
    };
    /**
     * Initial legacy slider
     * @param {HTMLElement} el
     */
    Swiper.prototype.sliderOurLegacy = function (el) {
        new SwiperCore(el, {
            breakpoints: {
                576: {
                    direction: 'horizontal',
                    slidesPerView: 2,
                },
                993: {
                    direction: 'vertical',
                    slidesPerView: 3,
                    spaceBetween: 25,
                    pagination: {
                        el: '.swiper-our-legacy-pagination',
                        clickable: true,
                    },
                },
                0: {
                    direction: 'horizontal',
                    initialSlide: 1,
                    slidesPerView: 1.5,
                    spaceBetween: 0,
                },
            },
            loop: true,
            centeredSlides: true,
            slideToClickedSlide: true,
            updateOnWindowResize: true,
            resizeObserver: true,
            preloadImages: false,
            lazy: true,
            watchSlidesVisibility: true,
            watchSlidesVisibility: true,
        });
    };
    /**
     * Initial review slider
     * @param {HTMLElement} el
     */
    Swiper.prototype.sliderReviewWithPagination = function (el) {
        var parentElement = el.closest('[data-slider-with-pagination]');
        // eslint-disable-next-line no-new
        new SwiperCore(el, {
            allowTouchMove: true,
            updateOnWindowResize: true,
            speed: 500,
            autoplay: {
                delay: 5000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
            },
            pagination: {
                el: parentElement.querySelector('[data-slider-with-pagination] .swiper-pagination'),
                clickable: true,
            },
        });
    };
    return Swiper;
}());
export { Swiper };
